import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./custom.css";
import Login from "./app/Login/Login";
import Home from "./app/Home/Home";
import AuthProvider from "./providers/AuthProvider";



export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <AuthProvider>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/" component={Home} />
                </Switch>
            </AuthProvider>
        );
    }
}
