import jwt_decode from "jwt-decode";
import cookie from "react-cookies";
import { DEVICE_INFO_INIT, PLATFORM, REGEX } from "./Constants";
import { SeoProductModel } from "./TFUHelmet";
const FontStyle = {
    Normal: 0,
    Italic: 1,
};
var Common = {
    isValidHttpUrl(string) {
        return /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(string);
    },
    actionType: {
        Insert: 1,
        Update: 2,
        Delete: 3,
    },
    truncateString(str, length) {
        if (str !== undefined && length < str.length) return str.substring(0, length) + "...";
        return str;
    },
    isValidEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    },
    isValidCode(code) {
        var re = /^([a-zA-Z0-9_]*)$/;
        return re.test(code.toLowerCase());
    },
    isHexColorValue(string) {
        return REGEX.HEX_COLOR.test(string);
    },
    isNumber(number) {
        var re = /^[+-]?\d+(\.\d+)?$/;
        return re.test(number);
    },
    isUserName(username) {
        var re = /^[\w\d]+$/;
        return re.test(username);
    },
    isDesktop() {
        var isMobile = false; //initiate as false
        // device detection
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                navigator.userAgent
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                navigator.userAgent.substr(0, 4)
            )
        ) {
            isMobile = true;
        }
        return !isMobile;
        // return window.innerWidth > 760;
    },
    isAdmin(role) {
        return role == "admin";
    },
    generateGuid() {
        return Common.s4() + Common.s4() + "-" + Common.s4() + "-" + Common.s4() + "-" + Common.s4() + "-" + Common.s4() + Common.s4() + Common.s4();
    },
    s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    },
    randomId(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    },
    Paging: {
        pageSize: 10,
        pageNumber: 1,
        keyword: "",
    },
    TransactionStatus: [
        {
            value: 0,
            text: "All",
        },
        {
            value: 1,
            text: "Processing",
        },
        {
            value: 2,
            text: "Succeed",
        },
        {
            value: 3,
            text: "Error",
        },
        {
            value: 4,
            text: "Canceled",
        },
        {
            value: 5,
            text: "Unpaid",
        },
        {
            value: 6,
            text: "ReadyToShip",
        },
        {
            value: 7,
            text: "Shipped",
        },
        {
            value: 8,
            text: "Returned",
        },
    ],
    PaymentMethod: [
        {
            value: 1,
            text: "COD",
        },
        {
            value: 2,
            text: "Momo",
        },
    ],
    formatDate(date, type) {
        if (date == undefined || date == null || date == "Invalid Date") return "";
        var day = this.formatNumber(date.getDate(), 2);
        var month = this.formatNumber(date.getMonth() + 1, 2);
        var year = this.formatNumber(date.getFullYear(), 4);
        var hours = this.formatNumber(date.getHours(), 2);
        var minutes = this.formatNumber(date.getMinutes(), 2);
        var seconds = this.formatNumber(date.getSeconds(), 2);
        var result = day + "/" + month + "/" + year;
        switch (type) {
            case "fulldate":
                result += " " + hours + ":" + minutes + ":" + seconds;
                break;
            case "datetime":
                result += " " + hours + ":" + minutes;
                break;
            case "timedate":
                result = hours + ":" + minutes + " " + result;
                break;
            case "vietnamese":
                result = `${day} tháng ${month}, ${year}`;
                break;
            case "jsondate":
                result = date.toISOString();
            case "time":
                result = hours + ":" + minutes + ":" + seconds;
            case "month":
                result = day + "/" + month ;
                break;
        }
        return result;
    },
    formatNumber(number, length) {
        var result = "";
        for (var i = 0; i < length; i++) {
            result += "0";
        }
        result += number;
        return result.slice(-length);
    },
    formatCurrency(currency, shortFormat, suffix) {
        if (!currency) return 0;
        if (shortFormat && currency) {
            return currency >= 1000 ? Math.round(currency / 1000).toString() + "K" : Math.round(currency).toString() + "K";
        }
        let text = currency.toLocaleString("vi", { style: "currency", currency: "VND" });
        text = text.replace(/\s/g, "");
        return text;
    },
    formatNumberNormal(number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    PressNumber(e) {
        var keypressed = null;
        if (window.event) {
            keypressed = window.event.keyCode;
        } else {
            keypressed = e.which;
        }
        if (keypressed < 48 || keypressed > 57) {
            if (keypressed == 8 || keypressed == 127) {
                return;
            }
            e.preventDefault();
        }
    },
    formatToK(number) {
        if (number.toString().length > 4) {
            return Math.floor(number / 1000).toString() + "K";
        }
        return number.toString();
    },
    generateAvatarByText(name, email) {
        let avatar = null;
        if (name && name.trim().length > 0) {
            var arr = name.trim().split(" ");
            if (arr && arr.length > 0) {
                if (arr.length == 1) {
                    avatar = arr[0].substring(0, 1);
                } else {
                    var temp1 = arr[arr.Length - 2].substring(0, 1);
                    var temp2 = arr[arr.Length - 1].substring(0, 1);
                    avatar = temp1 + temp2;
                }
            }
        } else if (email && email.trim().length > 0) {
            avatar = email.substring(0, 1);
        }
        return avatar;
    },
    UrlFriendlyCategory: [
        {
            value: 1,
            label: "Danh  mục sản phẩm",
        },
        {
            value: 2,
            label: "Sản phẩm",
        },
        {
            value: 3,
            label: "Campaign",
        },
    ],
    GetToken: () => {
        let token = cookie.load("token");
        return token;
    },
    GetRanusUUID: () => {
        let ruuid = cookie.load("ruuid");
        if (typeof ruuid == "undefined") return 0;
        return +ruuid;
    },
    CheckSeller: () => {
        var token = Common.GetToken();
        var isSeller = false;
        if (token) {
            const jwtDecode = require("jwt-decode");
            let decode = jwtDecode(token);
            isSeller = decode.is_seller === "True";
        }
        return isSeller;
    },
    CheckAdmin: () => {
        var token = Common.GetToken();
        var isAdmin = false;
        if (token) {
            let decode = jwt_decode(token);
            isAdmin = decode.is_admin === "True";
        }
        return isAdmin;
    },
    isChromeIOS() {
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); //là safari
        var isChromeIOS = navigator.userAgent.match("CriOS"); //là chrome trên ios
        var isDesktop = Common.isDesktop();

        if (isSafari && isChromeIOS && !isDesktop) return true;
        return false;
    },
    isIOS() {
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); //là safari
        var isChromeIOS = navigator.userAgent.match("CriOS"); //là chrome trên ios
        var isDesktop = Common.isDesktop();

        if ((isSafari || isChromeIOS) && !isDesktop) return true;
        return false;
    },
    RemoveToken: () => {
        cookie.remove("token", { path: "/" });
        sessionStorage.removeItem("tfuToken");
    },
    DetectPlatform: (flatform) => {
        let pathname = window.location.pathname;
        if (pathname && pathname.length > 0) {
            if (pathname.indexOf("lotus") >= 0 || flatform == "lotus") return PLATFORM.LOTUS;
            if (pathname.indexOf("facebook") >= 0 || flatform == "facebook") return PLATFORM.Facebook;
            if (pathname.indexOf("lazada") >= 0 || flatform == "lazada") return PLATFORM.Lazada;
            if (pathname.indexOf("shopee") >= 0 || flatform == "shopee") return PLATFORM.Shopee;
            if (pathname.indexOf("tiki") >= 0 || flatform == "tiki") return PLATFORM.Tiki;
            if (pathname.indexOf("undefined") >= 0 || flatform == "undefined") return PLATFORM.Undefined;
        }
        return PLATFORM.LOTUS;
    },
    DetectPlatformName: (platform) => {
        switch (platform) {
            case PLATFORM.Facebook:
                return "facebook";
            case PLATFORM.Lazada:
                return "lazada";
            case PLATFORM.Shopee:
                return "shopee";
            case PLATFORM.Tiki:
                return "tiki";
            case PLATFORM.Undefined:
                return "undefined";
            default:
                return "lotus";
        }
    },
    //những platform được sử dụng trong hệ thống
    PlatformsActive: [
        {
            value: PLATFORM.Lazada,
            label: "Lazada",
        },
        {
            value: PLATFORM.Shopee,
            label: "Shopee",
        },
    ],
    /**
     * Group an array by property
     * @param {any[]} array An array can be grouped
     * @param {string} key proprety name
     */
    GroupArray(array, key) {
        const map = new Map();
        array.forEach((item) => {
            let keyValue = item[key];
            const collection = map.get(keyValue);
            if (!collection) {
                map.set(keyValue, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    },
    formatAddress(address) {
        let formatedAddress = [];
        if (address.street) formatedAddress.push(address.street);
        if (address.wardName) formatedAddress.push(address.wardName);
        if (address.districtName) formatedAddress.push(address.districtName);
        if (address.provinceName) formatedAddress.push(address.provinceName);
        return formatedAddress.join(", ");
    },
    backGround: [
        { left: "#ae4f3b", right: "#fca97f" },
        { left: "#167877", right: "#c4dc7c" },
        { left: "#ae4f3b", right: "#fca97f" },
        { left: "#167877", right: "#c4dc7c" },
        { left: "#ae4f3b", right: "#fca97f" },
    ],
    COLORS: ["#ff3333", "#000000", "#00cccc", "#cccc00", "#e600ac", "#0000ff", "#00aaff", "#00cc00"],
    getStringWithoutExtension(stringUrl) {
        return stringUrl.replace(/\.[^/.]+$/, "");
    },
    getWebpImage(stringUrl) {
        if (!stringUrl) return;
        let original = stringUrl.replace(/\.[^/.]+$/, "");
        let result = `${original}-600x600.webp`;
        return result;
    },
    getWebp300x300Image(stringUrl) {
        if (!stringUrl) return;
        let original = stringUrl.replace(/\.[^/.]+$/, "");
        let result = `${original}-300x300.webp`;
        return result;
    },
    getCompressBrandUrl(stringUrl) {
        if (!stringUrl) return;
        let original = stringUrl.replace(/\.[^/.]+$/, "");
        let result = `${original}-600x600.webp`;
        return result;
    },
    //sellPrice
    GetPrice(sellPrice, salePrice, type) {
        switch (type) {
            case "normalPrice":
                if (salePrice == 0) return 0;
                return sellPrice;
            case "specialPrice":
                if (salePrice == 0) return sellPrice;
                return salePrice;
            case "discountPercent":
                if (salePrice == 0) return 0;
                let discountPercent = Math.floor(((sellPrice - salePrice) / sellPrice) * 100);
                return discountPercent;
            default:
                return 0;
        }
    },
    GetParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    ProductAttributeDisplay(productName, attributes, printTypeString, colorDisplayName) {
        return `${productName}, ${attributes.reduce((prev, element, index) => {
            if (element.code !== "Color") prev += `${element.value || element.name}, `;
            return prev;
        }, "")}${printTypeString}, ${colorDisplayName}`;
    },
    structuredProductJSON(product = new SeoProductModel()) {
        let offers = product.offers.map((item, idx) => {
            return `{"@type":"Offer","price":"${item.price}","priceCurrency":"${item.priceCurrency}","itemCondition":"${item.condition}","availability":"${item.availability}","sale_price":"${item.salePrice}","sale_price_effective_date":"${item.salePriceEffectiveDay}","url":"${item.url}","size":"${item.size}","color":"${item.color}","condition":"${item.condition}","priceValidUntil":"${item.priceValidUntil}"}`;
        });
        let reviewString = +product.ratingValue > 0 && +product.reviewCount > 0 ? `"aggregateRating":{"@type":"AggregateRating","ratingValue":"${product.ratingValue}","reviewCount": "${product.reviewCount}"},` : "";
        return `{"@context":"https://schema.org","@type":"Product","productID":"${product.productID}","sku":"${product.sku}","name":"${product.name}","description":"${product.description}","price":"${product.price}","priceCurrency":"${product.priceCurrency}","priceValidUntil":"${product.priceValidUntil}","itemCondition":"${product.condition}","availability":"${product.availability}","sale_price":"${product.salePrice}","sale_price_effective_date":"${product.salePriceEffectiveDay}","condition":"${product.condition}","url":"${product.url}","image":["${product.image}"],"google_product_category":"${product.googleProductCategory}","brand":{"@type":"Brand","name":"${product.brand}"},${reviewString}"offers":[${offers}]}`;
    },
    structuredSiteBoxSearchJSON(siteBoxSearchModel) {
        return `{"@context":"https://schema.org","@type":"WebSite","url":"${siteBoxSearchModel.url}","potentialAction":{"@type":"SearchAction","target":{"@type":"EntryPoint","urlTemplate":"${siteBoxSearchModel.urlTemplate}"},"query-input":"required name=search_term_string"},"name":"Ranus","sameAs":["${siteBoxSearchModel.facebookUrl}","${siteBoxSearchModel.lazadaUrl}","${siteBoxSearchModel.shopeeUrl}"]}`;
    },
    structuredLogoJSON() {
        return `{"@context": "https://schema.org","@type": "Organization","url": "https://ranus.vn","logo": "https://ranus.vn/img/Logo-01.png"}`;
    },
    isPDP(pathName) {
        let regrex = /i.([0-9]+).([0-9]+)/;
        var isPdp = regrex.test(pathName) || pathName.includes("/product-detail-page/");
        return isPdp;
    },
    detectDeviceInfo() {
        let userAgent = navigator.userAgent;
        let deviceInfo = DEVICE_INFO_INIT.Desktop;
        let zaloInApp = userAgent.includes("Zalo");
        let tiktokInApp = userAgent.includes("Bytedance");
        let facebookInApp = userAgent.includes("FBAN");
        if (facebookInApp) deviceInfo = DEVICE_INFO_INIT.FaceBookInApp;
        if (zaloInApp) deviceInfo = DEVICE_INFO_INIT.ZaloInApp;
        if (tiktokInApp) deviceInfo = DEVICE_INFO_INIT.TiktokInApp;
        return deviceInfo;
    },
    isInAppBrowser() {
        let inApp = [DEVICE_INFO_INIT.FaceBookInApp, DEVICE_INFO_INIT.ZaloInApp, DEVICE_INFO_INIT.TiktokInApp];
        let deviceInfo = Common.detectDeviceInfo();
        if (inApp.includes(deviceInfo)) return true;
        else return false;
    },
    sumQuantityProductHandler(cart, cartCombo = []) {
        let sum = 0;
        for (const item of cart) {
            if (item.productId > 0 && !item.isGift) sum += item.quantity;
        }
        sum += cartCombo ? cartCombo.reduce((prev, item, idx) => (prev += item.quantity), 0) : 0;
        return sum;
    },
    stringIsNullOrEmpty(string) {
        return string == null || string == "" || string == undefined;
    },
    measureTextWidth(text, fontFamily, fontSize, fontWeight = 500, letterSpacing = 0, scale = 1, fontStyle = FontStyle.Normal) {
        const p = document.createElement("p");
        p.innerHTML = text;
        p.style.fontFamily = `"${fontFamily}"`;
        p.style.fontSize = `${fontSize}px`;
        p.style.fontWeight = +fontWeight;
        p.style.transform = `scale(${scale}, ${scale})`;
        p.style.fontStyle = fontStyle == FontStyle.Normal ? "normal" : "italic";
        p.style.letterSpacing = `${letterSpacing}px`;
        p.style.margin = 0;
        p.style.transformOrigin = "top left";
        p.style.visibility = "hidden";
        p.style.position = "absolute";
        p.style.whiteSpace = "pre";
        document.body.appendChild(p);
        let bounding = p.getBoundingClientRect();
        const width = Math.ceil(bounding.width);
        document.body.removeChild(p);
        return width;
    },
    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },
    getFileExtension(url) {
        // Get the last part of the URL after the last dot
        var ext = url.split(".").pop();

        // If there is a query string in the URL, remove it
        if (ext.indexOf("?") !== -1) {
            ext = ext.split("?")[0];
        }

        // If the extension is a path parameter, remove it
        if (ext.indexOf("/") !== -1) {
            ext = ext.split("/")[0];
        }

        return ext;
    },
    getFileFullName(url) {
        let extension = url.split(".").pop();
        let findIndex = url.indexOf(extension);
        let fileName = url.substring(0, findIndex - 1);
        return fileName;
    },
    getFileNameWithoutExtension(url) {
        let urlData = new URL(url);
        let pathname = urlData.pathname;
        let fileName = pathname.split("/").pop();
        let fileNameWithoutExtension = fileName.split(".").shift();
        return fileNameWithoutExtension;
    },
    hexToRgba(hex, alpha) {
        hex = hex.replace("#", "");
        var r = parseInt(hex.substring(0, 2), 16);
        var g = parseInt(hex.substring(2, 4), 16);
        var b = parseInt(hex.substring(4, 6), 16);
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    },
};
export default Common;
