import { createContext } from "react";

export const AuthContext = createContext({
    userId: null,
    facebookUserId: null,
    googleUserId: null,
    email: null,
    login: () => {},
    logout: () => {},
    personal: {},
    getUserById: () => {},
});
