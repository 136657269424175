import { useReducer, useCallback } from "react";

const formReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      const currentState = { ...state };
      currentState.inputs[action.inputId].value = action.value;
      currentState.inputs[action.inputId].isTouch = true;

      let formIsValid = true;
      for (const inputId in currentState.inputs) {
        formIsValid = formIsValid && !!currentState.inputs[inputId].value;
      }

      return {
        inputs: currentState.inputs,
        isValid: formIsValid,
      };

    case "SET":
      return {
        inputs: action.inputs,
        isValid: action.formIsValid,
      };

    case "CLEAR":
      const newState = { ...state };

      for (const inputId in state.inputs) {
        newState.inputs[inputId].value = "";
        newState.inputs[inputId].isTouch = false;
      }
      newState.isValid = false;

      return newState;
  }
};

export const useForm = (initialFormInput, initialFormValidity) => {
  const [form, dispatch] = useReducer(formReducer, {
    inputs: initialFormInput,
    isValid: initialFormValidity,
  });

  const inputChange = useCallback((id, value, isValid) => {
    dispatch({
      type: "CHANGE",
      inputId: id,
      value: value,
    });
  }, []);

  const inputClear = useCallback(() => {
    dispatch({
      type: "CLEAR",
    });
  }, []);

  const inputSet = useCallback((inputData) => {
    dispatch({
      type: "SET",
      inputs: inputData,
    });
  }, []);

  return { form, inputChange, inputSet, inputClear };
};
